import React, { useState, useEffect } from "react";
import "./CollectFiles.css";
import useVisible from "../../hooks/useVisible.jsx";
import getFonts from "../../utils/buttons/getFonts";
import getFootages from "../../utils/buttons/getFootages";
import zipTemplate from "../../utils/zipTemplate.js";
import handleClick from "../../utils/handleClick.js";

function CollectFiles({
  onPopupStateChange,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("CollectFilesVisibility");
  const [helpPdf, setHelpPdf] = useVisible("CollectFilesPDF");
  const [fonts, setFonts] = useVisible("CollectFilesFonts");
  const [footages, setFootages] = useVisible("CollectFilesFootages");
  const [zip, setZip] = useVisible("CollectFilesZip");
  const [type, setType] = useState("ae");
  const [format, setFormat] = useState("txt");

  useEffect(() => {
    localStorage.setItem("CollectFilesFormat", format);
  }, [format]); // Эффект отслеживает изменения format

  // Функция для переключения между 'rtf' и 'txt'
  const toggleFormat = () => {
    setFormat((currentFormat) => (currentFormat === "rtf" ? "txt" : "rtf"));
  };

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  // Убедитесь, что zipTemplate правильно обрабатывает и возвращает Promise

  const collectFiles = (pdf, fonts, footages, zip, format) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(ae2CollectFiles());`, function (path) {
      if (path) {
        // getFonts(pdf, fonts, format, type);
        // if (footages) {
        //   getFootages(format, type);
        // }

        // if (zip) {
        //   // Используйте Promise для ожидания завершения zipTemplate
        //   await new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       window.jsx.evalScript(
        //         `run(getTemplateFilesZip(${JSON.stringify({
        //           type: type,
        //           help: pdf,
        //           fonts: fonts,
        //           footages: footages,
        //         })}));`,
        //         function (data) {
        //           if (data) {
        //             zipTemplate(JSON.parse(data))
        //               .then(resolve) // Разрешаем Promise после завершения zipTemplate
        //               .catch(reject); // Отклоняем Promise в случае ошибки
        //           } else {
        //             resolve(); // Разрешаем Promise, если данных нет
        //           }
        //         }
        //       );
        //     }, 3000);
        //   });
        // }

        // Код здесь будет выполнен только после завершения zipTemplate
        window.jsx.evalScript(
          `run(openFolder(${JSON.stringify(path)}));`,
          function (result) {}
        );
      }
    });
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-folder"></i>
        </div>
        <div className="button-card__title">Collect Files</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        {/* <div className="render-group__checkbox-group">
          <input
            type="checkbox"
            id="helpPdf-collect-files"
            checked={helpPdf}
            onChange={() => {
              setHelpPdf(!helpPdf);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="helpPdf-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Help PDF</span>
          </label>
          <input
            type="checkbox"
            id="fonts-collect-files"
            checked={fonts}
            onChange={() => {
              setFonts(!fonts);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="fonts-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Google Fonts</span>
          </label>
          <input
            type="checkbox"
            id="footages-collect-files"
            checked={footages}
            onChange={() => {
              setFootages(!footages);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="footages-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Links Footages</span>
          </label>
          <input
            type="checkbox"
            id="zip-collect-files"
            checked={zip}
            onChange={() => {
              setZip(!zip);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="zip-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Create Zip</span>
          </label>
          <div className="collect-files-format" onClick={toggleFormat}>
            {format}
          </div>
        </div> */}

        <div className="videobolt-tools">
          <div
            onClick={() => collectFiles(false, false, false, false, format)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-folder"></i>
            <span>Collect Files</span>
          </div>
          <div
            onClick={() => handleClick("createHelpMA", type)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span>Help PDF</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type, "google")}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type, "adobe")}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-font"></i>
            <span>Adobe Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Footage Links</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectFiles;
