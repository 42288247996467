import "./App.css";
import mainApi from "../../utils/api.js";
import { INITIAL_TAGS } from "../../utils/constants.js";
import { saveCepStorage, getCepStorage } from "../../utils/cepStorage.js";
import { useCepStorage } from "../../hooks/useCepStorage.jsx";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { useState, useEffect, memo } from "react";
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import useVisible from "../../hooks/useVisible.jsx";
import { CATEGORIES } from "../../utils/constants";

import Login from "../Login/Login.jsx";
import Register from "../Register/Register.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import SubscriptionCheck from "../SubscriptionCheck/SubscriptionCheck.jsx";
import DeviceCheck from "../DeviceCheck/DeviceCheck.jsx";
import Header from "../Header/Header.jsx";
import Navigation from "../Navigation/Navigation.jsx";
import Tags from "../Tags/Tags.jsx";
import ButtonList from "../ButtonList/ButtonList.jsx";
import Footer from "../Footer/Footer.jsx";
import LoadData from "../LoadData/LoadData.jsx";
import TelegramLink from "../TelegramLink/TelegramLink.jsx";
import EmailVerifier from "../EmailVerifier/EmailVerifier.jsx";
import Preloader from "../Preloader/Preloader.jsx";

// Выносим MainContent в отдельный компонент
const MainContent = memo(
  ({
    currentUser,
    handleUpdateUser,
    handleSignOut,
    lastHash,
    findButton,
    setFindButton,
    selectedCategory,
    setSelectedCategory,
    selectedTag,
    setSelectedTag,
    tags,
    setTags,
    buttons,
    categories,
  }) => (
    <>
      <Header
        handleUpdateUser={handleUpdateUser}
        handleSignOut={handleSignOut}
        lastHash={lastHash}
        findButton={findButton}
        setFindButton={setFindButton}
      />
      <Navigation
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
        categories={categories}
      />
      <Tags
        currentTag={selectedTag}
        onTagChange={setSelectedTag}
        tags={tags}
        setTags={setTags}
      />
      <ButtonList
        currentUser={currentUser}
        saveCepStorage={saveCepStorage}
        getCepStorage={getCepStorage}
        useCepStorage={useCepStorage}
        useVisible={useVisible}
        buttons={buttons}
        search={findButton}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedTag={selectedTag}
        tags={tags}
      />
      <Footer />
    </>
  )
);

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(() => {
    const storedCategory = localStorage.getItem("selectedCategory");
    return typeof storedCategory === "string" && storedCategory.trim() !== ""
      ? storedCategory
      : "ae";
  });

  const [selectedTag, setSelectedTag] = useVisible(null);
  const [tags, setTags] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [findButton, setFindButton] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastHash, setLastHash] = useState(
    () => localStorage.getItem("lastHash") || ""
  );
  const [load, setLoad] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [device, setDevice] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [isTelegramLinked, setIsTelegramLinked] = useState(
    () => JSON.parse(localStorage.getItem("isTelegramLinked")) || false
  );
  const [emailVerifier, setEmailVerifier] = useState(
    () => JSON.parse(localStorage.getItem("emailVerifier")) || false
  );

  const [isInfoTooltip, setIsInfoTooltip] = useState({
    isOpen: false,
    status: true,
    text: "",
  });

  const closeInfoTooltip = () => {
    setIsInfoTooltip((prev) => ({ ...prev, isOpen: false }));
  };

  const handleRegister = ({
    name,
    family,
    login,
    email,
    password,
    telegram,
  }) => {
    setIsLoader(true);
    mainApi
      .createUser({
        name,
        family,
        login,
        email,
        password,
        telegram: { username: telegram },
      })
      .then((response) => {
        if (response.data.email === email) {
          handleLogin({ email, password });
        }
      })
      .catch((err) => {
        setServerError(err?.message || "An error occurred during registration");
      })
      .finally(() => setIsLoader(false));
  };

  const handleLogin = ({ email, password }) => {
    setIsLoader(true);
    mainApi
      .login({ email, password })
      .then((jwt) => {
        if (!jwt.token) throw new Error("JWT token is missing.");
        localStorage.setItem("jwt", jwt.token);
        return mainApi.getUserInfo();
      })
      .then((data) => {
        if (data) {
          setLoggedIn(true);
          setCurrentUser(data);
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        const errorMessage = err?.message || "An error occurred during login";
        setServerError(errorMessage);
        localStorage.removeItem("jwt");
      })
      .finally(() => setIsLoader(false));
  };

  const handleUpdateUser = (id, data) => {
    mainApi
      .updateUser(id, data)
      .then(() => {
        console.log("Update user info:", id);
      })
      .catch((error) => {
        console.error("Failed to update user info:", id, error);
        setIsInfoTooltip({
          isOpen: true,
          status: false,
          text: "Failed to update user info: " + error,
        });
      });
  };

  const handleSignOut = () => {
    if (currentUser?._id) {
      mainApi
        .updateUser(currentUser._id, {
          license: {
            status: false,
            hostName: "",
            userName: "",
          },
        })
        .catch((error) => {
          console.error(
            "Failed to update subscription for user:",
            currentUser._id,
            error
          );
        });
    }
    setCurrentUser({});
    setDevice(false);
    setLoggedIn(false);
    localStorage.removeItem("jwt");
  };

  // Эффект для проверки production среды
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      setDataLoaded(true);
    }
  }, []);

  // Эффект для инициализации пользователя
  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
      setLoad(true);
      return;
    }

    setIsLoader(true);
    mainApi
      .getUserInfo()
      .then((data) => {
        if (!data) return;

        setLoggedIn(true);
        setCurrentUser(data);

        if (!data.license?.status) return;

        window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
        window.jsx.evalScript(`run(userInfo());`, function (result) {
          if (!result || result === "undefined") return;

          const userInfo = JSON.parse(result);
          if (!device) {
            setDevice({
              hostName: userInfo.hostName,
              userName: userInfo.userName,
            });
          }

          if (userInfo.hostName === data.license.hostName) {
            const deviceUpdate = {
              license: {
                status: true,
                hostName: userInfo.hostName,
                userName: userInfo.userName,
              },
            };

            mainApi
              .updateUser(data._id, deviceUpdate)
              .then(setCurrentUser)
              .catch(console.error);
          }
        });
      })
      .catch((err) =>
        setIsInfoTooltip({
          isOpen: true,
          status: false,
          text: err?.message || "An error occurred while fetching data",
        })
      )
      .finally(() => {
        setIsLoader(false);
        setLoad(true);
        setLoading(false);
      });
  }, []);

  // Эффект для обновления пользовательских данных
  useEffect(() => {
    if (!currentUser) return;

    const {
      emailVerifier: emailVerifierData,
      telegram,
      tags: userTags,
    } = currentUser;

    if (emailVerifierData?.verify) {
      const emailVerify = emailVerifierData.verify;
      setEmailVerifier(emailVerify);
      localStorage.setItem("emailVerifier", JSON.stringify(emailVerify));
    }

    if (telegram?.isLinked) {
      setIsTelegramLinked(telegram.isLinked);
      localStorage.setItem(
        "isTelegramLinked",
        JSON.stringify(telegram.isLinked)
      );
    }

    if (userTags) {
      const savedTags = getCepStorage("tags");
      if (savedTags && savedTags.length > 0) {
        setTags(savedTags);
      } else {
        saveCepStorage("tags", INITIAL_TAGS);
        setTags(INITIAL_TAGS);
      }
    }
  }, [currentUser]);

  // Эффект для загрузки кнопок
  useEffect(() => {
    if (loggedIn) {
      setIsLoader(true);
      mainApi
        .getButtons()
        .then((fetchedButtons) => {
          setButtons(
            fetchedButtons.sort((a, b) => a.name.localeCompare(b.name))
          );
        })
        .catch((err) => {
          setIsInfoTooltip({ isOpen: true, status: false, text: err });
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && currentUser) {
      const availableCategories = CATEGORIES.filter((category) => {
        const hasSubscriptionAccess =
          !currentUser.subscription ||
          currentUser.subscription[category] !== false;

        const hasFreeOrUserButtons = buttons.some(
          (button) => button.category === category && button.access === "free"
        );

        return hasSubscriptionAccess || hasFreeOrUserButtons;
      });

      setFilteredCategories(availableCategories);
    }
  }, [buttons, loggedIn]);

  // Определяем роуты на верхнем уровне компонента
  const routes = [
    {
      path: "signup",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Register handleRegister={handleRegister} serverError={serverError} />
      ),
    },
    {
      path: "signin",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Login handleLogin={handleLogin} serverError={serverError} />
      ),
    },
    {
      path: "/",
      element: !loggedIn ? (
        <Navigate to="/signin" />
      ) : !emailVerifier ? (
        <EmailVerifier
          setEmailVerifier={setEmailVerifier}
          handleUpdateUser={handleUpdateUser}
          handleSignOut={handleSignOut}
        />
      ) : !isTelegramLinked ? (
        <TelegramLink
          setIsTelegramLinked={setIsTelegramLinked}
          handleUpdateUser={handleUpdateUser}
          handleSignOut={handleSignOut}
        />
      ) : !currentUser?.subscription?.status ? (
        <SubscriptionCheck handleSignOut={handleSignOut} />
      ) : !dataLoaded ? (
        process.env.NODE_ENV === "production" && (
          <LoadData
            isOpen={!dataLoaded}
            onDataLoaded={() => setDataLoaded(true)}
            lastHash={lastHash}
            setLastHash={setLastHash}
          />
        )
      ) : !(
          currentUser?.license?.hostName === device?.hostName ||
          currentUser?.role === "admin" ||
          currentUser?.login === "LordVaran"
        ) ? (
        <DeviceCheck
          device={device}
          setCurrentUser={setCurrentUser}
          setDevice={setDevice}
        />
      ) : (
        <MainContent
          currentUser={currentUser}
          handleUpdateUser={handleUpdateUser}
          handleSignOut={handleSignOut}
          lastHash={lastHash}
          findButton={findButton}
          setFindButton={setFindButton}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          tags={tags}
          setTags={setTags}
          buttons={buttons}
          categories={filteredCategories}
        />
      ),
    },
  ];

  // Используем роутер безусловно
  const router = useRoutes(routes);

  if (!load) {
    return <Preloader isOpen={true} />;
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div>
        <InfoTooltip onClose={closeInfoTooltip} isInfoTooltip={isInfoTooltip} />
        {isLoader ? <Preloader isOpen={!dataLoaded} /> : router}
      </div>
    </CurrentUserContext.Provider>
  );
}
