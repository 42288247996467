import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";
import { useState, useEffect } from "react";

function TransformPreCompose({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("TransformPreCompVisibility");
  const [textLayer, setTextLayer] = useState(() => {
    const localState = localStorage.getItem("preCompTextLayer");
    return localState !== null ? JSON.parse(localState) : true;
  });

  const [shapeLayer, setShapeLayer] = useState(() => {
    const localState = localStorage.getItem("preCompShapeLayer");
    return localState !== null ? JSON.parse(localState) : true;
  });

  useEffect(() => {
    localStorage.setItem("preCompTextLayer", JSON.stringify(textLayer));
  }, [textLayer]);

  useEffect(() => {
    localStorage.setItem("preCompShapeLayer", JSON.stringify(shapeLayer));
  }, [shapeLayer]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-solid fa-down-to-line"></i>
          <div />
        </div>
        <div className="button-card__title">Transform Pre-Compose</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="preCompTextLayer"
              checked={textLayer}
              onChange={() => setTextLayer(!textLayer)}
              className="hidden-checkbox"
            />
            <label htmlFor="preCompTextLayer">
              <span className="custom-checkbox"></span>
              <span className="label-text">Text Layer</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="preCompShapeLayer"
              checked={shapeLayer}
              onChange={() => setShapeLayer(!shapeLayer)}
              className="hidden-checkbox"
            />
            <label htmlFor="preCompShapeLayer">
              <span className="custom-checkbox"></span>
              <span className="label-text">Shape Layer</span>
            </label>
          </div>
        </div>
        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick("transformPreCompose", { type: "layer" })
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span>Selected Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick("transformPreCompose", {
                type: "comp",
                text: textLayer,
                shape: shapeLayer,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick("transformPreCompose", {
                type: "selected comp",
                text: textLayer,
                shape: shapeLayer,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick("transformPreCompose", {
                type: "project",
                text: textLayer,
                shape: shapeLayer,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-photo-film"></i>
            <span>Project</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransformPreCompose;
