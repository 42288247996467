import React, { useState, useEffect } from "react";
import handleClick from "../../utils/handleClick.js";

function FollowLayer({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const FADE_DELAY = `${optionsPath}.fadeDelay`;
  const OPTION_DELAY = `${optionsPath}.delay`;
  const [fadeDelay, setFadeDelay] = useCepStorage(FADE_DELAY);
  const [delay, setDelay] = useState(getCepStorage(OPTION_DELAY) || "");

  useEffect(() => {
    saveCepStorage(OPTION_DELAY, delay);
  }, [delay]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
          <div />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            className="rename__input shape__input"
            type="number"
            value={delay}
            onChange={(e) => setDelay(e.target.value)}
            placeholder="Delay"
          />
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="fadeDelay"
              checked={fadeDelay}
              onChange={() => {
                setFadeDelay(!fadeDelay);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="fadeDelay">
              <span className="custom-checkbox"></span>
              <span className="label-text">Fade Delay</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick("followLayer", {
                delay: delay,
                fadeDelay: fadeDelay,
              });
            }}
            className="render-group__button"
          >
            <i class="fa-regular fa-link"></i>
            <span className="render-group__button-text">Create Follow</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default FollowLayer;
