// cepStorage.js
import { USER_FOLDER, EXTENSION_ID } from "./constants";

const PATH = `${USER_FOLDER}/Harchenko/${EXTENSION_ID.toLowerCase()}-settings.json`;

// Cache for storing data in memory
const memoryCache = {
  data: null,
  lastRead: 0,
  // Cache TTL in milliseconds (5 minutes)
  TTL: 5 * 60 * 1000,
};

// Debounce helper
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Read entire file content with caching
function readFileContent() {
  const now = Date.now();

  // Return cached data if it's still valid
  if (memoryCache.data && now - memoryCache.lastRead < memoryCache.TTL) {
    return memoryCache.data;
  }

  if (process.env.NODE_ENV === "production") {
    const result = window.cep.fs.readFile(PATH);

    if (result.err === 0) {
      try {
        memoryCache.data = JSON.parse(result.data);
        memoryCache.lastRead = now;
        return memoryCache.data;
      } catch (e) {
        console.warn("Ошибка парсинга JSON, создается новый файл.");
        memoryCache.data = {};
        memoryCache.lastRead = now;
        return memoryCache.data;
      }
    }
    return {};
  } else {
    // In development, use localStorage as storage
    try {
      const allData = localStorage.getItem("cepStorage");
      if (allData) {
        memoryCache.data = JSON.parse(allData);
      } else {
        memoryCache.data = {};
      }
      memoryCache.lastRead = now;
      return memoryCache.data;
    } catch (e) {
      console.error("Error reading from localStorage:", e);
      return {};
    }
  }
}

// Debounced write function
const debouncedWrite = debounce((data) => {
  if (process.env.NODE_ENV === "production") {
    const writeResult = window.cep.fs.writeFile(
      PATH,
      JSON.stringify(data, null, 2)
    );
    if (writeResult.err !== 0) {
      console.error("Ошибка при сохранении данных:", writeResult.err);
    }
  } else {
    try {
      localStorage.setItem("cepStorage", JSON.stringify(data));
    } catch (e) {
      console.error("Error writing to localStorage:", e);
    }
  }
}, 1000); // Debounce for 1 second

function setNestedValue(obj, keyPath, value) {
  const keys = keyPath.split(".");
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }
  current[keys[keys.length - 1]] = value;
}

function getNestedValue(obj, keyPath) {
  const keys = keyPath.split(".");
  let current = obj;
  for (const key of keys) {
    if (current && key in current) {
      current = current[key];
    } else {
      return null;
    }
  }
  return current;
}

export function saveCepStorage(key, data) {
  const fileData = readFileContent();
  setNestedValue(fileData, key, data);
  memoryCache.data = fileData;
  debouncedWrite(fileData);
}

export function getCepStorage(key) {
  const fileData = readFileContent();
  return getNestedValue(fileData, key);
}
