import React, { useEffect, useState } from "react";
import { useData } from "../../contexts//DataContext";

const DataLoader = ({ children, setIsVisible }) => {
  const { data, setData } = useData();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let intervalId;

    const loadData = async () => {
      try {
        const response = await fetch("./vb-validate-data.json");
        if (!response.ok) {
          throw new Error("Failed to load template data");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const updateData = (prevSelection) => {
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
      window.jsx.evalScript(
        `run(vbValidator({selection:[${prevSelection}]}));`,
        function (result) {
          if (result && result !== "undefined") {
            result = JSON.parse(result);
            setData(result);
            setLoading(false);
          } else {
            setLoading(false);
            setIsVisible(false);
            clearInterval(intervalId); // Очищаем интервал, если данных больше нет
          }
        }
      );
    };

    if (process.env.NODE_ENV !== "production") {
      loadData();
    } else {
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

      // Первичный запуск
      window.jsx.evalScript(
        `run(vbValidator({selection:app.project.selection}));`,
        function (result) {
          if (result && result !== "undefined") {
            result = JSON.parse(result);
            setData(result);
            setLoading(false);

            // Устанавливаем интервал для обновления
            // intervalId = setInterval(() => {
            //   updateData(result.selection);
            // }, 2500);
          } else {
            setLoading(false);
            setIsVisible(false);
          }
        }
      );
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Очистка при размонтировании
    };
  }, []); // useEffect срабатывает только один раз

  if (loading) {
    return <div className="loading">Loading ...</div>;
  }

  if (error) {
    return <div className="error">Error loading: {error}</div>;
  }

  if (!data) {
    return null;
  }

  return children(data);
};

export default DataLoader;
