import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";
import "./RenderLayers.css";

function RenderLayers({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const H264 = "H.264";
  const QUICK_TIME = "QuickTime";
  const FORMAT = `${optionsPath}.format`;
  const SET_WHITE_COLOR = `${optionsPath}.setWhiteColor`;
  const SAVE_FILL = `${optionsPath}.saveFill`;

  const [format, setFormat] = useCepStorage(FORMAT);
  const [whiteColor, setWhiteColor] = useVisible(SET_WHITE_COLOR);
  const [saveFill, setSaveFill] = useVisible(SAVE_FILL);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
          <div />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Format</span>
            <select
              id="type"
              name="type"
              onChange={(event) => setFormat(event.target.value)}
              value={format}
            >
              <option value={H264}>MP4</option>
              <option value={QUICK_TIME}>MOV Alpha</option>
            </select>
          </div>
          <input
            type="checkbox"
            id="setWhiteColor"
            checked={whiteColor}
            onChange={() => setWhiteColor(!whiteColor)}
            className="hidden-checkbox"
          />
          <label htmlFor="setWhiteColor">
            <span className="custom-checkbox"></span>
            <span className="label-text">Set White Color</span>
          </label>
          <input
            type="checkbox"
            id="saveFill"
            checked={saveFill}
            onChange={() => setSaveFill(!saveFill)}
            className="hidden-checkbox"
          />
          <label htmlFor="saveFill">
            <span className="custom-checkbox"></span>
            <span className="label-text">Save Fill</span>
          </label>
        </div>
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("renderLayer", {
                format: format,
                setWhiteColor: whiteColor,
                saveFill: saveFill,
                type: "layer",
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span className="render-group__button-text">Render Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick("renderLayer", {
                format: format,
                setWhiteColor: whiteColor,
                saveFill: saveFill,
                type: "selected comp",
              })
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Render Items</span>
          </div>
          {/* <div
            onClick={() =>
              handleClick("layerToFootageImport", {
                saveFill: saveFill,
              })
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-rotate"></i>
            <span className="render-group__button-text">Replace Layers</span>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default RenderLayers;
