import React, { useEffect } from "react";
import {
  Image,
  Type,
  Palette,
  Sliders,
  AlertCircle,
  List,
  CheckSquare,
  RotateCcw,
  MapPin,
  Box, // Используем Box вместо Cube
} from "lucide-react";
import MediaValidation from "./MediaValidation";
import TextValidation from "./TextValidation";
import EffectValidation from "./EffectValidation"; // Новый компонент
import "./ParamComponent.css";

const ParamComponent = ({ param, renameEffect }) => {
  // Функция проверки валидности параметра
  const isParamValid = () => {
    if (!param.validate) return true; // Если нет данных о валидации — считаем валидным
    return Object.values(param.validate).every((value) => value.valid === true);
  };

  const paramIsValid = isParamValid();

  const openComp = (id) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`
      var comp = app.project.itemByID(${Number(id)});
      if (comp && comp instanceof CompItem) {
          comp.openInViewer();
          comp.time = comp.duration / 2; // Если все же не работает, попробуйте app.project.activeItem.time
      } else {
          alert("Composition not found or invalid ID");
      }
  `);
  };

  // Сообщаем родительскому компоненту, валиден ли этот параметр
  // useEffect(() => {
  //   onValidateChange(param.name, paramIsValid);
  // }, [paramIsValid, onValidateChange, param.name]);

  return (
    <div className={`param-container ${paramIsValid ? "" : "invalid-param"}`}>
      <div className="param-header">
        {/* Иконка ошибки, если параметр невалидный */}
        {!paramIsValid && <AlertCircle size={16} className="error-icon" />}

        {/* Рендер иконки в зависимости от типа */}
        {(param.type === "media-placeholder" ||
          param.type === "image-placeholder") && (
          <Image size={16} className="icon" />
        )}
        {param.type === "text-placeholder" && (
          <Type size={16} className="icon" />
        )}
        {param.type === "color-control" && (
          <Palette size={16} className="icon" />
        )}
        {param.type === "slider-control" && (
          <Sliders size={16} className="icon" />
        )}
        {param.type === "dropdown-control" && (
          <List size={16} className="icon" />
        )}
        {param.type === "checkbox-control" && (
          <CheckSquare size={16} className="icon" />
        )}
        {param.type === "angle-control" && (
          <RotateCcw size={16} className="icon" />
        )}
        {param.type === "point-2d-control" && (
          <MapPin size={16} className="icon" />
        )}
        {param.type === "point-3d-control" && (
          <Box size={16} className="icon" />
        )}

        <div className="param-name" onClick={() => openComp(param.obj.itemId)}>
          {param.name}
        </div>
      </div>
      {/* <span>{param.type === "text-placeholder" && param.text}</span> */}

      {/* Рендер компонента валидации в зависимости от типа */}
      {param.type === "media-placeholder" ||
      param.type === "image-placeholder" ? (
        <MediaValidation
          containingComp={param.containingComp}
          validate={param.validate}
          imagePath={param.imagePath}
        />
      ) : param.type === "text-placeholder" ? (
        <TextValidation param={param} validate={param.validate} />
      ) : [
          "color-control",
          "slider-control",
          "checkbox-control",
          "dropdown-control",
          "angle-control",
          "point-2d-control",
          "point-3d-control",
        ].includes(param.type) ? (
        <EffectValidation param={param} renameEffect={renameEffect} />
      ) : null}
    </div>
  );
};

export default ParamComponent;
