import handleClick from "../../utils/handleClick.js";
import React, { useEffect, useState } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./Rename.css";

function Rename({
  onPopupStateChange,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("RenameGroupVisibility");
  const [newName, setNewName] = useVisible("RenameGroupNewName");
  const [suffixRename, setSuffixRename] = useVisible("suffixRename");
  const [prefixRename, setPrefixRename] = useVisible("prefixRename");
  const [prefixCount, setPrefixCount] = useVisible("prefixCount");
  const [suffixCount, setSuffixCount] = useVisible("suffixCount");
  const [zeroCount, setZeroCount] = useVisible("skipZeroCount");

  const [prefix, setPrefix] = useState(
    localStorage.getItem("prefixRenameText") || ""
  );
  const [text, setText] = useState(localStorage.getItem("renameText") || "");
  const [suffix, setSuffix] = useState(
    localStorage.getItem("suffixRenameText") || ""
  );

  useEffect(() => {
    localStorage.setItem("prefixRenameText", prefix);
  }, [prefix]);

  useEffect(() => {
    localStorage.setItem("renameText", text);
  }, [text]);

  useEffect(() => {
    localStorage.setItem("suffixRenameText", suffix);
  }, [suffix]);

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-light fa-input-text"></i>
        </div>
        <div className="button-card__title">Rename</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="newName"
              checked={newName}
              onChange={() => {
                setNewName(!newName);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="newName">
              <span className="custom-checkbox"></span>
              <span className="label-text">New Name</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="prefixRename"
              checked={prefixRename}
              onChange={() => {
                setPrefixRename(!prefixRename);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="prefixRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Prefix Text</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="prefixCount"
              checked={prefixCount}
              onChange={() => setPrefixCount(!prefixCount)}
              className="hidden-checkbox"
            />
            <label htmlFor="prefixCount">
              <span className="custom-checkbox"></span>
              <span className="label-text">Prefix Count</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="suffixRename"
              checked={suffixRename}
              onChange={() => {
                setSuffixRename(!suffixRename);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="suffixRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Suffix Text</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="suffixCount"
              checked={suffixCount}
              onChange={() => setSuffixCount(!suffixCount)}
              className="hidden-checkbox"
            />
            <label htmlFor="suffixCount">
              <span className="custom-checkbox"></span>
              <span className="label-text">Suffix Count</span>
            </label>
          </div>
          <div
            className={`render-group__checkbox ${
              !prefixCount && !suffixCount ? "hidden" : ""
            }`}
          >
            <input
              type="checkbox"
              id="zeroCount"
              checked={zeroCount}
              onChange={() => setZeroCount(!zeroCount)}
              className="hidden-checkbox"
            />
            <label htmlFor="zeroCount">
              <span className="custom-checkbox"></span>
              <span className="label-text">Skip 0 Count</span>
            </label>
          </div>
        </div>
        <div className="rename__input-group">
          <input
            className={`rename__input ${!prefixRename ? "hidden" : ""}`}
            type="text"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            disabled={!prefixRename}
            placeholder="Prefix"
          />
          <input
            className={`rename__input ${!newName ? "hidden" : ""}`}
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="New Name"
          />
          <input
            className={`rename__input ${!suffixRename ? "hidden" : ""}`}
            type="text"
            value={suffix}
            onChange={(e) => setSuffix(e.target.value)}
            disabled={!suffixRename}
            placeholder="Suffix"
          />
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("ae2RenameLayers", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                replace: newName,
                text: text,
                type: "layer",
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span className="render-group__button-text">Selected Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick("ae2RenameEffect", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                replace: newName,
                text: text,
              })
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-regular fa-square-e"></i>
            <span className="render-group__button-text">Selected Effects</span>
          </div>
          <div
            onClick={() =>
              handleClick("ae2Rename", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                replace: newName,
                text: text,
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-image"></i>
            <span className="render-group__button-text">Selected Items</span>
          </div>
          <div
            onClick={() =>
              handleClick("ae2RenameFolder", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                replace: newName,
                text: text,
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-folder"></i>
            <span className="render-group__button-text">Items in Folder</span>
          </div>
          <div
            onClick={() =>
              handleClick("ae2RenameLayers", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                text: text,
                type: "selected comp",
                matchType: "text",
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-text-size"></i>
            <span className="render-group__button-text">Text in Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick("ae2RenameLayers", {
                prefix: prefixRename ? prefix : "",
                suffix: suffixRename ? suffix : "",
                prefixCount: prefixCount,
                suffixCount: suffixCount,
                zeroCount: zeroCount,
                text: text,
                type: "selected comp",
                matchType: "shape",
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-full"></i>
            <span className="render-group__button-text">Shape in Comp</span>
          </div>
          <div
            onClick={() => handleClick("ae2RenameTextCompName", false)}
            className="render-group__button"
          >
            <i class="fa-light fa-message-image"></i>
            <span className="render-group__button-text">Comp for Layer</span>
          </div>
          <div
            onClick={() => handleClick("ae2RenameEffectIndex", false)}
            className="render-group__button"
          >
            <i class="fa-regular fa-square-1"></i>
            <span className="render-group__button-text">Effect Index</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rename;
