import React, { useState } from "react";
import {
  ChevronDown,
  ChevronRight,
  AlertCircle,
  CheckCircle,
} from "lucide-react";
import ParamComponent from "./ParamComponent";
import "./GroupComponent.css";

const GroupComponent = ({ group, params, isGroupValid, renameEffect }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleGroup = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`group-container ${
        isGroupValid ? "valid-group" : "invalid-group"
      }`}
    >
      <div
        onClick={toggleGroup}
        className={`group-header ${isExpanded ? "active" : ""} ${
          !isGroupValid ? "invalid" : ""
        }`}
      >
        <div className="group-title-container">
          {!isGroupValid ? (
            <AlertCircle size={20} className="error-icon" />
          ) : (
            <CheckCircle size={20} className="valid-icon" />
          )}
          <span className="group-title">{group.name}</span>
        </div>
        {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
      </div>

      {isExpanded && (
        <div className="group-content expanded">
          <div className="group-body">
            {group.paramKeys.map((paramKey, paramIndex) => {
              const param = params[paramKey];
              if (!param) return null;

              return (
                <div key={paramIndex} className="group-param">
                  <ParamComponent param={param} renameEffect={renameEffect} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupComponent;
