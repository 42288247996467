import React, { useState, useEffect, useContext, useMemo } from "react";
import update from "immutability-helper";
import "./ButtonList.css";
import ButtonCard from "../ButtonCard/ButtonCard.jsx";
import EaseKey from "../EaseKey/EaseKey.jsx";
import ParagraphButtons from "../ParagraphButtons/ParagraphButtons.jsx";
import AnchorAlignNull from "../AnchorAlignNull/AnchorAlignNull.jsx";
import ChangeCompositionSettings from "../ChangeCompositionSettings/ChangeCompositionSettings.jsx";
import COMPONENTS from "../../utils/components.js";

const useDynamicPositioning = (targetSelector, navSelector) => {
  useEffect(() => {
    const targetElement = document.querySelector(targetSelector);
    const navElement = document.querySelector(navSelector);

    if (!targetElement || !navElement) return;

    const updatePosition = () => {
      const targetRect = targetElement.getBoundingClientRect();
      navElement.style.top = `${targetRect.bottom}px`;
      if (navSelector === ".buttons-card") {
        navElement.style.paddingBottom = `${
          targetRect.bottom + window.scrollY
        }px`;
      }
    };

    updatePosition();

    const resizeObserver = new ResizeObserver(updatePosition);
    const mutationObserver = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          (mutation.attributeName === "style" ||
            mutation.attributeName === "class")
        ) {
          updatePosition();
        }
      }
    });

    const config = {
      attributes: true,
      attributeFilter: ["style", "class"],
      subtree: true,
    };

    resizeObserver.observe(targetElement);
    mutationObserver.observe(targetElement, config);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [targetSelector, navSelector]);
};

const ButtonList = ({
  currentUser,
  useVisible,
  saveCepStorage,
  getCepStorage,
  useCepStorage,
  buttons,
  search,
  selectedCategory,
  selectedTag,
  tags,
}) => {
  const [activeSlide, setActiveSlide] = useVisible("activeSlide");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useDynamicPositioning(".tags-container__tags", ".additional__navigation");
  useDynamicPositioning(".additional__navigation", ".sliderContainer");
  useDynamicPositioning(".sliderContainer", ".paragraphs-cards");
  useDynamicPositioning(".paragraphs-cards", ".buttons-card");

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredButtons = useMemo(() => {
    console.log("фильтрация кнопок");
    return buttons.filter((button) => {
      // Проверяем доступность для пользователя
      const hasAccess =
        currentUser.subscription[button.category] ||
        currentUser.role === "admin" ||
        button.access === "free";

      // При поиске проверяем только поисковой запрос и доступность
      if (search) {
        return (
          button.name.toLowerCase().includes(search.toLowerCase()) && hasAccess
        );
      }

      // Без поиска фильтруем по категории и доступности
      return button.category === selectedCategory && hasAccess;
    });
  }, [buttons, search, selectedCategory, currentUser.subscription]);

  const componentMap = useMemo(() => {
    const map = {};
    Object.keys(COMPONENTS).forEach((key) => {
      map[key.toLowerCase()] = COMPONENTS[key];
    });
    return map;
  }, []);

  const toggleNavigation = (indx) =>
    setActiveSlide(activeSlide === indx ? false : indx);

  const additionalNavigation = [
    {
      component: <EaseKey />,
      icon: <i className="fa-regular fa-rhombus"></i>,
      helpTip: "Keyframe Easing",
    },
    {
      component: <AnchorAlignNull script={"$.ae.moveAnchor"} />,
      icon: <i className="fa-solid fa-anchor"></i>,
      helpTip: "Anchor Point",
    },
    {
      component: <AnchorAlignNull script={"alignTo"} />,
      icon: (
        <i className="fa-sharp fa-solid fa-objects-align-center-vertical"></i>
      ),
      helpTip: "Align Layers",
    },
    {
      component: <AnchorAlignNull script={"newNull"} />,
      icon: <i className="fa-sharp fa-solid fa-layer-plus"></i>,
      helpTip: "Add Null",
    },
    {
      component: <ChangeCompositionSettings />,
      icon: <i className="fa-regular fa-gear"></i>,
      helpTip: "Comp Setting",
    },
  ];

  return (
    <>
      <div className="additional__navigation">
        {additionalNavigation.map((obj, indx) => (
          <div
            className={`additional__navigation_item ${
              activeSlide === indx ? "additional__navigation_item--active" : ""
            }`}
            onClick={() => toggleNavigation(indx)}
            title={obj.helpTip}
            key={indx}
          >
            {obj.icon}
          </div>
        ))}
      </div>
      <div className="sliderContainer">
        {additionalNavigation[activeSlide] && (
          <>{additionalNavigation[activeSlide].component}</>
        )}
      </div>
      <ParagraphButtons />
      <section className="buttons-card">
        {filteredButtons.length ? (
          <ul className="buttons-card__list">
            {filteredButtons.map((button) => {
              const buttonName = button.name
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]+/g, "");
              const ComponentToRender = componentMap[buttonName]; // Получаем компонент из map
              const isAdmin = currentUser.role === "admin";
              const isFree = button.access === "free";
              const isUserOrTest =
                currentUser.role === "user" || currentUser.role === "test";
              const isNotAdminButton = button.access !== "admin";
              const hasSubscription =
                isFree || !!currentUser.subscription?.[selectedCategory];
              const hasAccess =
                isAdmin ||
                (hasSubscription && isUserOrTest && isNotAdminButton);
              return hasAccess ? (
                <ButtonCard
                  currentUser={currentUser}
                  saveCepStorage={saveCepStorage}
                  getCepStorage={getCepStorage}
                  useCepStorage={useCepStorage}
                  useVisible={useVisible}
                  key={button._id}
                  button={button}
                  selectedTag={selectedTag}
                  tags={tags}
                  ComponentToRender={ComponentToRender}
                />
              ) : null;
            })}
          </ul>
        ) : null}
      </section>
    </>
  );
};

export default ButtonList;
