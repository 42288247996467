import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function CreatePlaceholder({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  category,
}) {
  const [emptyPlaceholder, setEmptyPlaceholder] = useVisible(
    "MogrtEmptyPlaceholder"
  );
  let script;
  let name = "Create Drop Zone";

  if (category === "mg") {
    script = "mogrtPlaceholder";
    name = "Create Placeholder";
  }

  if (category === "ae") {
    script = "mogrtPlaceholder";
    name = "Create Placeholder";
  }

  if (category === "pp") {
    script = "ppFillPlaceholder";
    name = "Fill Placeholder";
  }

  if (category === "am" || category === "dr") {
    script = "motionDropZone";
  }

  if (category === "gt") {
    script = "psPlaceholder";
  }

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-image"></i>
          <div />
        </div>
        <div className="button-card__title">{name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        {category === "mg" ? (
          <div className="render-group__checkbox-group">
            <div className="render-group__checkbox">
              <input
                type="checkbox"
                id="mogrt-empty-placeholder"
                checked={emptyPlaceholder}
                onChange={() => setEmptyPlaceholder(!emptyPlaceholder)}
                className="hidden-checkbox"
              />
              <label htmlFor="mogrt-empty-placeholder">
                <span className="custom-checkbox"></span>
                <span className="label-text">Empty Placeholder</span>
              </label>
            </div>
          </div>
        ) : null}
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(script, {
                auto: true,
                emptyPlaceholder: emptyPlaceholder,
                replacePlaceholder: false,
                notification: true,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-bolt"></i>
            <span>Auto</span>
          </div>
          <div
            onClick={() =>
              handleClick(script, {
                auto: false,
                emptyPlaceholder: emptyPlaceholder,
                replacePlaceholder: false,
                notification: true,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comps</span>
          </div>
          {category === "mg" ? (
            <div
              onClick={() =>
                handleClick(script, {
                  auto: false,
                  replacePlaceholder: true,
                  notification: true,
                })
              }
              className="resolution-tools__button"
            >
              <i class="fa-solid fa-rotate"></i>
              <span>Replace To Empty Placeholder</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default CreatePlaceholder;
