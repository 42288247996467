import React from "react";

const EffectValidation = ({ param, renameEffect }) => {
  return (
    <div className="param-info">
      {Object.entries(param.validate)
        .filter(([_, value]) => value !== undefined && !value.valid) // Только невалидные эффекты
        .map(([key, value]) => (
          <div key={key}>
            <p
              className="param-description param-script"
              onClick={() => renameEffect(param)}
              // title={value.description}
            >
              <strong>{value.name}</strong>: Rename to: "{value.fixed}"
            </p>
            <span className="span-opacity">{value.description}</span>
          </div>
        ))}
    </div>
  );
};

export default EffectValidation;
