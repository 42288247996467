import handleClick from "../../utils/handleClick.js";
import "./ParagraphButtons.css";

const ParagraphButtons = () => {
  const buttons = [
    {
      argument: "left",
      icon: '<i class="fa-regular fa-align-left"></i>',
    },
    {
      argument: "center",
      icon: '<i class="fa-regular fa-align-center"></i>',
    },
    {
      argument: "right",
      icon: '<i class="fa-regular fa-align-right"></i>',
    },
  ];

  return (
    <section className="paragraphs-cards">
      {buttons.map((btn, index) => (
        <li
          key={index}
          className="paragraphs-card button-icon"
          onClick={() => handleClick("ae2Paragraph", btn.argument)}
        >
          <div className="button-card__paragraph">
            <div dangerouslySetInnerHTML={{ __html: btn.icon }} />
          </div>
        </li>
      ))}
    </section>
  );
};

export default ParagraphButtons;
