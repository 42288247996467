import React, { useEffect, useState, useRef } from "react";
import DataLoader from "./DataLoader.js";
import GroupComponent from "./GroupComponent.jsx";
import useVisible from "../../hooks/useVisible.jsx";
import Modal from "../Modal/Modal.jsx";
import { useData } from "../../contexts/DataContext.jsx";
import "./VideoboltValidator.css";

const VideoboltValidator = ({
  selectedTag,
  buttonTags,
  generateButtonTags,
}) => {
  const popupRef = useRef(null);
  const { data, setData } = useData();
  const [isVisible, setIsVisible] = useVisible("vbValidatorVisibility");
  const [showModal, setShowModal] = useState(false);
  const [hasData, setHasData] = useState(true);
  const [groupValidity, setGroupValidity] = useState({});
  const [hasInvalidControlGroup, setHasInvalidControlGroup] = useState(false);

  const handleCloseModal = () => {
    setIsVisible(false);
    setShowModal(false);
  };

  const isControlGroup = (group, data) => {
    return group.paramKeys.some((paramKey) => {
      const param = data.variants.default.params[paramKey];
      return (
        param &&
        [
          "color-control",
          "slider-control",
          "dropdown-control",
          "checkbox-control",
          "angle-control",
          "point-2d-control",
          "point-3d-control",
        ].includes(param.type)
      );
    });
  };

  // Проверка валидности группы
  const validateGroup = (group, params) => {
    return group.paramKeys.every((paramKey) => {
      const param = params[paramKey];
      return param && param.validate
        ? Object.values(param.validate).every((v) => v.valid === true)
        : true;
    });
  };

  // Функция для переименования невалидных параметров контроллеров
  const renameInvalidControllerParams = () => {
    if (!data || !data.variants) return;

    const updatedData = { ...data };
    const params = updatedData.variants.default.params;

    Object.values(params).forEach((param) => {
      if (
        param.validate &&
        param.validate.name &&
        param.validate.name.valid === false
      ) {
        renameEffect(param);
      }
    });

    setData(updatedData);
  };

  // Функция переименования
  const renameEffect = (param) => {
    const newName = param.validate.name.fixed;

    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(param.validate.name.script, function (result) {});

    const paramPath = Object.keys(data.variants.default.params).find(
      (key) => data.variants.default.params[key] === param
    );

    if (!paramPath) {
      console.error("Параметр не найден!");
      return;
    }

    // Обновляем данные
    const updatedData = { ...data };
    updatedData.variants.default.params[paramPath].name = newName;
    updatedData.variants.default.params[paramPath].validate.name.valid = true;

    setData(updatedData);
  };

  // Выполняем валидацию всех групп и определяем наличие невалидных контрольных групп
  useEffect(() => {
    if (data && data.variants) {
      const newGroupValidity = {};
      let invalidControlGroupExists = false;

      data.variants.default.defaultParamGroups.forEach((group) => {
        const isValid = validateGroup(group, data.variants.default.params);
        newGroupValidity[group.name] = isValid;

        if (!isValid && isControlGroup(group, data)) {
          invalidControlGroupExists = true;
        }
      });

      setGroupValidity(newGroupValidity);
      setHasInvalidControlGroup(invalidControlGroupExists);
    }
  }, [data]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => {
          if (hasData) {
            setIsVisible(!isVisible);
          }
        }}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-light fa-square-check"></i>
        </div>
        <div className="button-card__title">Videobolt Validator</div>
        {generateButtonTags()}
      </li>
      {isVisible && hasData && (
        <div className="render-group">
          <Modal isOpen={showModal} onClose={handleCloseModal}>
            {/* <div ref={popupRef} className="validator-popup"> */}
            <DataLoader setIsVisible={setIsVisible}>
              {(data) => {
                const sortedGroups = [
                  ...data.variants.default.defaultParamGroups,
                ]
                  .filter((group) => group.paramKeys?.length)
                  .map((group) => ({ ...group }))
                  .sort((a, b) => {
                    const aIsControl = isControlGroup(a, data);
                    const bIsControl = isControlGroup(b, data);

                    if (aIsControl === bIsControl) {
                      return a.name.localeCompare(b.name);
                    }
                    return aIsControl ? -1 : 1;
                  });

                return (
                  <div className="app-container">
                    {/* Кнопка внизу, если есть невалидные группы с контроллерами */}
                    {/* {hasInvalidControlGroup && (
                      <div className="fix-invalid-controllers">
                        <button
                          onClick={renameInvalidControllerParams}
                          className="fix-button"
                        >
                          Quick Fixed
                        </button>
                      </div>
                    )} */}
                    <div className="content">
                      <div className="groups">
                        {sortedGroups.map((group, index) => (
                          <GroupComponent
                            key={index}
                            group={group}
                            params={data.variants.default.params}
                            isGroupValid={groupValidity[group.name]}
                            renameEffect={renameEffect}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                );
              }}
            </DataLoader>
            {/* </div> */}
          </Modal>
        </div>
      )}
    </>
  );
};

export default VideoboltValidator;
