import React, { useState, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import "./MogrtGroupItem.css";
const DND_ITEM_TYPE = "ITEM";
const DND_GROUP_TYPE = "GROUP";

function IconBasedOnType(type) {
  switch (type) {
    case 5:
      return <i class="fa-light fa-arrows-up-down-left-right"></i>;
    case 2:
      return <i class="fa-regular fa-slider"></i>;
    case 9:
      return <i class="fa-light fa-arrows-maximize"></i>;
    case 4:
      return <i class="fa-solid fa-eye-dropper"></i>;
    case 1:
      return <i class="fa-sharp fa-regular fa-square-check"></i>;
    case 13:
      return <i class="fa-light fa-list-dropdown"></i>;
    case 6:
      return <i class="fa-regular fa-text-size"></i>;
    case 14:
      return <i class="fa-sharp fa-regular fa-image"></i>;
    case 3:
      return <i class="fa-regular fa-arrows-rotate"></i>;
    default:
      return null;
  }
}

function convertToRGBA(value) {
  const values = value.split(",").map((num) => parseFloat(num) * 255);
  return `rgba(${values[0]}, ${values[1]}, ${values[2]}, ${values[3]})`;
}

function MogrtGroupItem({
  item,
  id,
  moveItem,
  isSelected,
  onSelect,
  isInGroup,
  updateItemProperty,
}) {
  const ref = useRef(null);
  const [isInputFocused, setInputFocused] = useState(false);

  const [, drop] = useDrop({
    accept: [DND_ITEM_TYPE, DND_GROUP_TYPE],
    hover(draggedItem) {
      if (draggedItem.id !== item.id) {
        moveItem(draggedItem.id, item.id, "before");
        onSelect(draggedItem.id, false);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: { id: item.id },
    canDrag: !isInputFocused,
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && monitor.getItem().id === item.id,
    }),
  });

  function handleProperty(script) {
    if (process.env.NODE_ENV !== "development") {
      window.jsx.evalScript(script.toString(), function (filePath) {});
    }
  }

  drag(drop(ref));

  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
      }}
      className={`mogrt-group__item ${isInGroup ? "item-in-group" : ""}`}
      style={{
        backgroundColor: isSelected ? "#161616" : "",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div className="mogrt-group__header">
        <div className="mogrt-group__item-icon">
          {IconBasedOnType(item.propertyType.type)}
        </div>
        <input
          className={`item__input ${item.isNameError ? "input-error" : ""}`}
          value={item.newName !== undefined ? item.newName : item.name}
          onChange={(e) => {
            const newName = e.target.value;
            updateItemProperty(id, "name", newName);
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
      </div>
      {item.propertyType.type === 2 && (
        <div className="mogrt__group-sliders">
          <div className="mogrt-group__item-slider">
            <input
              className="item__input-slider"
              type="number"
              maxLength="4"
              value={item.min}
              onChange={(e) => updateItemProperty(id, "min", e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
            <input
              className="item__input-slider"
              type="number"
              maxLength="4"
              value={item.max}
              onChange={(e) => updateItemProperty(id, "max", e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          </div>
          <input
            className="item__range-slider"
            type="range"
            min={item.min}
            max={item.max}
            value={item.value}
            onChange={(e) => {
              // console.log("Slider value:", e.target.value);
              onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
              updateItemProperty(id, "value", e.target.value);
              handleProperty(`${item.script}.setValue(${e.target.value})`);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        </div>
      )}

      {item.propertyType.type === 4 && (
        <div
          className="item__color"
          style={{ backgroundColor: convertToRGBA(item.value) }}
        ></div>
      )}
      {item.propertyType.type === 6 && (
        <div className="mogrt_item__text">{item.value}</div>
      )}
      {/*
      {item.propertyType.type === 14 && (
        <img src={item.path} alt="Placeholder" />
      )} */}
    </div>
  );
}

export default MogrtGroupItem;
