import React from "react";
import "./Navigation.css";

const Navigation = ({ categories, selectedCategory, onSelectCategory }) => {
  const handleCategoryClick = (category) => {
    if (typeof category === "string" && category) {
      localStorage.setItem("selectedCategory", category); // Сохраняем текущую категорию в localStorage
      onSelectCategory(category); // Обновляем категорию
    }
  };

  return (
    <div className="navigation">
      {categories.map((category) => (
        <div
          key={category}
          className={`navigation__item ${
            selectedCategory === category ? "navigation__item--active" : ""
          }`}
          onClick={() => handleCategoryClick(category)}
        >
          {category.toUpperCase()}
        </div>
      ))}
    </div>
  );
};

export default Navigation;
