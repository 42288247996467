import ResolutionTools from "../components/ResolutionTools/ResolutionTools.jsx";
import ColorControls from "../components/ColorControls/ColorControls.jsx";
import ExportElementsPremiere from "../components/ExportElementsPremiere/ExportElementsPremiere.jsx";
import Markers from "../components/Markers/Markers.jsx";
import MotionAnimator from "../components/MotionAnimator/MotionAnimator.jsx";
import RenderLayers from "../components/RenderLayers/RenderLayers.jsx";
import CreateGroup from "../components/MogrtGroup/MogrtGroup.jsx";
import BakeExpression from "../components/BakeExpression/BakeExpression.jsx";
import Pins3D from "../components/Pins3D/Pins3D.jsx";
import TransformPreCompose from "../components/TransformPreCompose/TransformPreCompose.jsx";
import UnPreComposeText from "../components/UnPreComposeText/UnPreComposeText.jsx";
import AutoMogrt from "../components/AutoMogrt/AutoMogrt.jsx";
import TransitionMogrt from "../components/TransitionMogrt/TransitionMogrt.jsx";
import Rename from "../components/Rename/Rename.jsx";
import ExportMotion from "../components/ExportMotion/ExportMotion.jsx";
import SlicedScale from "../components/SlicedScale/SlicedScale.jsx";
import ShapeGroupAnchor from "../components/ShapeGroupAnchor/ShapeGroupAnchor.jsx";
import BakeParent from "../components/BakeParent/BakeParent.jsx";
import NewTemplate from "../components/NewTemplate/NewTemplate.jsx";
import VideoBolt from "../components/VideoBolt/VideoBolt.jsx";
import ConvertShape from "../components/ConvertShape/ConvertShape.jsx";
import ShapeMatte from "../components/ShapeMatte/ShapeMatte.jsx";
import ExportPremiere from "../components/ExportPremiere/ExportPremiere.jsx";
import DisableAnimator from "../components/DisableAnimator/DisableAnimator.jsx";
import ImportGraphics from "../components/ImportGraphics/ImportGraphics.jsx";
import AutoCrop from "../components/AutoCrop/AutoCrop.jsx";
import MaskConvert from "../components/MaskConvert/MaskConvert.jsx";
import ExportDavinci from "../components/ExportDavinci/ExportDavinci.jsx";
import ExportElementsDaVinci from "../components/ExportElementsDaVinci/ExportElementsDaVinci.jsx";
import FlipValue from "../components/FlipValue/FlipValue.jsx";
import AutoCamera from "../components/AutoCamera/AutoCamera.jsx";
import ReverseKeyMotion from "../components/ReverseKeyMotion/ReverseKeyMotion.jsx";
import BakeProperty from "../components/BakeProperty/BakeProperty.jsx";
import ShapeToBezierPath from "../components/ShapeToBezierPath/ShapeToBezierPath.jsx";
import AutoColorsScheme from "../components/AutoColorsScheme/AutoColorsScheme.jsx";
import ParallaxAnimator from "../components/ParallaxAnimator/ParallaxAnimator.jsx";
import ExpressionControls from "../components/ExpressionControls/ExpressionControls.jsx";
import BakeShapeLayers from "../components/BakeShapeLayers/BakeShapeLayers.jsx";
import RenderImportFootage from "../components/RenderImportFootage/RenderImportFootage.jsx";
import Space3D from "../components/Space3D/Space3D.jsx";
import MacroIcons from "../components/MacroIcons/MacroIcons.jsx";
import TitlesMogrt from "../components/TitlesMogrt/TitlesMogrt.jsx";
import ShapeToImage from "../components/ShapeToImage/ShapeToImage.jsx";
import TransitionsDropZone from "../components/TransitionsDropZone/TransitionsDropZone.jsx";
import ReviewTools from "../components/ReviewTools/ReviewTools.jsx";
import Grid from "../components/Grid/Grid.jsx";
import FillPlaceholders from "../components/FillPlaceholders/FillPlaceholders.jsx";
import SmartPreview from "../components/SmartPreview/SmartPreview.jsx";
import ColorsSchemes from "../components/ColorsSchemes/ColorsSchemes.jsx";
import FollowLayer from "../components/FollowLayer/FollowLayer.jsx";
import CollectFiles from "../components/CollectFiles/CollectFiles.jsx";
import ExportMogrt from "../components/ExportMogrt/ExportMogrt.jsx";
import Collapse from "../components/Collapse/Collapse.jsx";
import NormalizeTrackMatte from "../components/NormalizeTrackMatte/NormalizeTrackMatte.jsx";
import AutoBeat from "../components/AutoBeat/AutoBeat.jsx";
import AddControl from "../components/AddControl/AddControl.jsx";
import ExportGraphics from "../components/ExportGraphics/ExportGraphics.jsx";
import CreatePlaceholder from "../components/CreatePlaceholder/CreatePlaceholder.jsx";
import ResponsiveText from "../components/ResponsiveText/ResponsiveText.jsx";
import MaskToComp from "../components/MaskToComp/MaskToComp.jsx";
import SplitTextLayer from "../components/SplitTextLayer/SplitTextLayer.jsx";
import TransitionPreview from "../components/TransitionPreview/TransitionPreview.jsx";
import SplitTemplate from "../components/SplitTemplate/SplitTemplate.jsx";
import BakePlaceholder from "../components/BakePlaceholder/BakePlaceholder.jsx";
import VideoboltValidator from "../components/VideoboltValidator/VideoboltValidator.jsx";
import Flicker from "../components/Flicker/Flicker.jsx";
import InsertText from "../components/InsertText/InsertText.jsx";
import RenderVideoMatte from "../components/RenderVideoMatte/RenderVideoMatte.jsx";
import SmartPreCompose from "../components/SmartPreCompose/SmartPreCompose.jsx";

const COMPONENTS = {
  ResolutionTools,
  ColorControls,
  ExportElementsPremiere,
  Markers,
  MotionAnimator,
  RenderLayers,
  CreateGroup,
  BakeExpression,
  Pins3D,
  TransformPreCompose,
  UnPreComposeText,
  AutoMogrt,
  TransitionMogrt,
  Rename,
  ExportMotion,
  SlicedScale,
  ShapeGroupAnchor,
  BakeParent,
  NewTemplate,
  VideoBolt,
  ConvertShape,
  ShapeMatte,
  ExportPremiere,
  DisableAnimator,
  ImportGraphics,
  AutoCrop,
  MaskConvert,
  ExportDavinci,
  ExportElementsDaVinci,
  FlipValue,
  AutoCamera,
  ReverseKeyMotion,
  BakeProperty,
  ShapeToBezierPath,
  AutoColorsScheme,
  ParallaxAnimator,
  ExpressionControls,
  BakeShapeLayers,
  RenderImportFootage,
  Space3D,
  MacroIcons,
  TitlesMogrt,
  ShapeToImage,
  TransitionsDropZone,
  ReviewTools,
  Grid,
  FillPlaceholders,
  SmartPreview,
  ColorsSchemes,
  FollowLayer,
  CollectFiles,
  ExportMogrt,
  Collapse,
  NormalizeTrackMatte,
  AutoBeat,
  AddControl,
  ExportGraphics,
  CreatePlaceholder,
  ResponsiveText,
  MaskToComp,
  SplitTextLayer,
  TransitionPreview,
  SplitTemplate,
  // BakePlaceholder,
  VideoboltValidator,
  Flicker,
  InsertText,
  RenderVideoMatte,
  SmartPreCompose,
};

export default COMPONENTS;
