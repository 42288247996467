import useVisible from "../../hooks/useVisible.jsx";

function ShapeToBezierPath({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ShapeToBezierVisibility");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-bezier-curve"></i>
          <div />
        </div>
        <div className="button-card__title">Shape To Bezier Path</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick(
              "shapeToBezier",
              JSON.stringify({
                type: "layer",
                shapeType: "all",
                notification: true,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "shapeToBezier",
              JSON.stringify({
                type: "comp",
                shapeType: "all",
                notification: true,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "shapeToBezier",
              JSON.stringify({
                type: "project",
                shapeType: "all",
                notification: true,
              })
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-photo-film"></i>
          <span>Project</span>
        </div>
      </div>
    </>
  );
}

export default ShapeToBezierPath;
