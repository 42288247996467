import React, { useState, useEffect } from "react";
import handleClick from "../../utils/handleClick.js";

function AutoCrop({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const ANIMATION_TIME = `${optionsPath}.animationTime`;
  const PADDING = `${optionsPath}.padding`;
  const [time, setTime] = useCepStorage(ANIMATION_TIME);
  const [padding, setPadding] = useState(getCepStorage(PADDING) || "");

  useEffect(() => {
    saveCepStorage(PADDING, padding);
  }, [padding]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            className="rename__input shape__input"
            type="number"
            value={padding}
            onChange={(e) => setPadding(e.target.value)}
            placeholder="Padding"
          />
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id={ANIMATION_TIME}
              checked={time}
              onChange={() => {
                setTime(!time);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor={ANIMATION_TIME}>
              <span className="custom-checkbox"></span>
              <span className="label-text">Animation</span>
            </label>
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick("autoCrop", {
                type: "layer",
                time: time,
                padding: padding || 0,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span>Selected Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick("autoCrop", {
                type: "comp",
                time: time,
                padding: padding || 0,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick("autoCrop", {
                type: "selected comp",
                time: time,
                padding: padding || 0,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comp</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoCrop;
