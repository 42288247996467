import React from "react";
import ReactDOM from "react-dom";
import "./Modal.css"; // Подключаем стили

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.className === "modal-overlay") {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <span className="modal-close" onClick={onClose}>
          &times;
        </span>
        {children}
      </div>
    </div>,
    document.body // Монтируем в body, чтобы избежать влияния других стилей
  );
}

export default Modal;
