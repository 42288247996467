import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function SplitTemplate({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("SplitTemplateVisibility");
  const [parts, setParts] = useVisible("SplitTemplateParts");
  const [userParts, setUserParts] = useState(
    localStorage.getItem("SplitTemplateUserParts") || 2
  );

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-regular fa-columns-3"></i>
        </div>
        <div className="button-card__title">Split Template</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="parts"
              checked={parts}
              onChange={() => {
                setParts(!parts);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="parts">
              <span className="custom-checkbox"></span>
              <span className="label-text">User Parts</span>
            </label>
          </div>

          {parts && (
            <input
              className="rename__input shape__input"
              type="number"
              value={userParts}
              onChange={(e) => setUserParts(e.target.value)}
              onBlur={() => {
                if (Number(userParts) < 2) {
                  setUserParts(2);
                }
              }}
              placeholder="Parts"
              min="2"
            />
          )}
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("splitTemplate", {
                userParts: false,
              })
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-scissors"></i>
            <span className="render-group__button-text">Split</span>
          </div>
          <div
            onClick={() =>
              handleClick("splitTemplate", {
                userParts: userParts,
              })
            }
            className={`render-group__button ${!parts ? "hidden" : ""}`}
          >
            <i class="fa-sharp fa-solid fa-divide"></i>
            <span className="render-group__button-text">Parts</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SplitTemplate;
