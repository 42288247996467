import handleClick from "../../utils/handleClick.js";
import useVisible from "../../hooks/useVisible.jsx";

function BakeShapeLayers({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const H264 = "H.264";
  const QUICK_TIME = "QuickTime";
  const FORMAT = `${optionsPath}.format`;

  const [format, setFormat] = useCepStorage(FORMAT);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-sharp fa-regular fa-camera-movie"></i>
        </div>
        <div className="button-card__title">Bake Shape Layers</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Format</span>
            <select
              id="type"
              name="type"
              onChange={(event) => setFormat(event.target.value)}
              value={format}
            >
              <option value={H264}>MP4</option>
              <option value={QUICK_TIME}>MOV Alpha</option>
            </select>
          </div>
          {/* Checkbox for Random */}
          {/* <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="comp"
              checked={checkedOption === "comp"}
              onChange={() => handleCheckboxChange("comp")}
              className="hidden-checkbox"
            />
            <label htmlFor="comp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Active Comp</span>
            </label>
          </div> */}

          {/* Checkbox for Step */}
          {/* <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="selected comp"
              checked={checkedOption === "selected comp"}
              onChange={() => handleCheckboxChange("selected comp")}
              className="hidden-checkbox"
            />
            <label htmlFor="selected comp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Selected Comp</span>
            </label>
          </div> */}

          {/* Checkbox for Step */}
          {/* <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="project"
              checked={checkedOption === "project"}
              onChange={() => handleCheckboxChange("project")}
              className="hidden-checkbox"
            />
            <label htmlFor="project">
              <span className="custom-checkbox"></span>
              <span className="label-text">Project</span>
            </label>
          </div> */}
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick("bakeShapeLayers", { type: "comp", format: format });
            }}
            className="render-group__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span className="render-group__button-text">Active Comp</span>
          </div>
          <div
            onClick={() => {
              handleClick("bakeShapeLayers", {
                type: "selected comp",
                format: format,
              });
            }}
            className="render-group__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Selected Comp</span>
          </div>
          <div
            onClick={() => {
              handleClick("bakeShapeLayers", {
                type: "project",
                format: format,
              });
            }}
            className="render-group__button"
          >
            <i class="fa-regular fa-photo-film"></i>
            <span className="render-group__button-text">Project</span>
          </div>

          {/* <div
            onClick={() => {
              handleClick("ae2ppImportFootage", { type: checkedOption });
            }}
            className="render-group__button"
          >
            <i class="fa-light fa-video-arrow-down-left"></i>
            <span className="render-group__button-text">Import Footage</span>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BakeShapeLayers;
