import React, { useState, useEffect } from "react";
import handleClick from "../../utils/handleClick.js";

function SmartPreCompose({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const ANIMATION = `${optionsPath}.animation`;
  const OPTIMIZE_SIZE = `${optionsPath}.optimizeSize`;
  const [animation, setAnimation] = useCepStorage(ANIMATION);
  const [optimizeSize, setOptimizeSize] = useCepStorage(OPTIMIZE_SIZE);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
          <div />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="animation"
              checked={animation}
              onChange={() => {
                setAnimation(!animation);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="animation">
              <span className="custom-checkbox"></span>
              <span className="label-text">Animation</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="optimizeSize"
              checked={optimizeSize}
              onChange={() => {
                setOptimizeSize(!optimizeSize);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="optimizeSize">
              <span className="custom-checkbox"></span>
              <span className="label-text">Optimize Size</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick("smartPreCompose", {
                animation: animation,
                optimizeSize: optimizeSize,
              });
            }}
            className="render-group__button"
          >
            <div dangerouslySetInnerHTML={{ __html: button.icon }} />
            <span className="render-group__button-text">
              Create Pre-Compose
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmartPreCompose;
