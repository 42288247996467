import React, { useState, useEffect } from "react";
import handleClick from "../../utils/handleClick.js";

function Flicker({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const ANIMATION_TYPE = `${optionsPath}.animationType`;
  const FRAMES_DURATION = `${optionsPath}.framesDuration`;
  const [animationType, setAnimationType] = useCepStorage(ANIMATION_TYPE);
  const [duration, setDuration] = useCepStorage(FRAMES_DURATION);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-solid fa-bolt"></i>
        </div>
        <div className="button-card__title">Flicker</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          {/* Второй выпадающий список */}
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Animation Type</span>
            <select
              id="type"
              name="type"
              onChange={(event) => setAnimationType(event.target.value)}
              value={animationType}
            >
              <option value="1">In Point</option>
              <option value="2">Out Point</option>
              <option value="3">In - Out Point</option>
              <option value="4">Loop</option>
            </select>
          </div>
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Frames Duration</span>
            <input
              type="number"
              id="duration"
              name="duration"
              className="rename__input"
              onChange={(event) => setDuration(event.target.value)}
              value={duration}
            />
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("aeFlicker", {
                animationType: Number(animationType),
                framesDuration: Number(duration),
              })
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-solid fa-bolt"></i>
            <span className="render-group__button-text">Add Flicker</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Flicker;
