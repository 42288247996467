import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";
import "./RenderVideoMatte.css";

function RenderVideoMatte({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const [changeName, setChangeName] = useVisible("changeName");

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
          <div />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="changeName"
              checked={changeName}
              onChange={() => setChangeName(!changeName)}
              className="hidden-checkbox"
            />
            <label htmlFor="changeName">
              <span className="custom-checkbox"></span>
              <span className="label-text">Change Name</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("renderVideoMatte", {
                type: true,
                setName: changeName,
              })
            }
            className="render-group__button"
          >
            <i className="fa-regular fa-layer-plus"></i>
            <span className="render-group__button-text">Layer</span>
          </div>
          <div
            onClick={() =>
              handleClick("renderVideoMatte", {
                type: false,
                setName: changeName,
              })
            }
            className="render-group__button"
          >
            <i className="fa-regular fa-layer-group"></i>
            <span className="render-group__button-text">Layer Group</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenderVideoMatte;
