import React from "react";

const MediaValidation = ({ containingComp, validate, imagePath }) => {
  return (
    <div className="param-info">
      <div>
        {/* <p>Comp: {containingComp}</p> */}
        {Object.entries(validate)
          .filter(([_, value]) => value !== undefined && !value.valid) // Фильтруем только невалидные свойства
          .map(([key, value]) => (
            <p key={key} className="param-description">
              <strong>{value.name}</strong>: {value.description}
            </p>
          ))}
      </div>
      {/* {imagePath && (
        <img
          src={`./images/${imagePath}`}
          alt="Media"
          className="param-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/api/placeholder/320/180";
          }}
        />
      )} */}
    </div>
  );
};

export default MediaValidation;
