import React, { useEffect, useState, useRef } from "react";
import handleClick from "../../utils/handleClick.js";
import { hexToRgbArray, rgbArrayToHex } from "../../utils/colors.js";

function InsertText({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
  button,
}) {
  const TEXT_STRING = `${optionsPath}.textString`;
  const TEXT_CAPS = `${optionsPath}.textCaps`;
  const FONT_SIZE = `${optionsPath}.fontSize`;
  const COLOR = `${optionsPath}.color`;
  const SPLIT_TYPE = `${optionsPath}.splitType`;
  const MARGIN = `${optionsPath}.margin`;
  const ALIGNMENT = `${optionsPath}.alignment`;

  const [fontSize, setFontSize] = useCepStorage(FONT_SIZE);
  const [textCaps, setTextCaps] = useCepStorage(TEXT_CAPS);
  const [splitType, setSplitType] = useCepStorage(SPLIT_TYPE);
  const [margin, setMargin] = useCepStorage(MARGIN);
  const [alignment, setAlignment] = useCepStorage(ALIGNMENT);
  const [text, setText] = useState(getCepStorage(TEXT_STRING) || "");
  const [color, setColor] = useState(getCepStorage(COLOR) || "#ffffff");

  const textareaRef = useRef(null);

  // Update height when text changes or on initial render
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    saveCepStorage(TEXT_STRING, text);
  }, [text]);

  useEffect(() => {
    saveCepStorage(COLOR, color);
  }, [color]);

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height on initial render or when `text` changes
  }, [text, isVisible]);

  const handleColorPicker = () => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      "run($.ae.colorPicker(" + JSON.stringify(hexToRgbArray(color)) + "));",
      function (data) {
        if (data) {
          data = data.split(",");
          setColor(rgbArrayToHex(data));
        }
      }
    );
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <div dangerouslySetInnerHTML={{ __html: button.icon }} />
        </div>
        <div className="button-card__title">{button.name}</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Split Type</span>
            <select
              id="type"
              name="type"
              onChange={(event) => setSplitType(event.target.value)}
              value={splitType}
            >
              <option value="words">Words</option>
              <option value="lines">Lines</option>
            </select>
          </div>
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Alignment</span>
            <select
              id="alignment"
              name="alignment"
              onChange={(event) => setAlignment(event.target.value)}
              value={alignment}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </div>
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Margin</span>
            <input
              type="number"
              id={MARGIN}
              name={MARGIN}
              className="rename__input"
              onChange={(event) => setMargin(event.target.value)}
              value={margin}
            />
          </div>
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Font Size</span>
            <input
              type="number"
              id={FONT_SIZE}
              name={FONT_SIZE}
              className="rename__input"
              onChange={(event) => setFontSize(event.target.value)}
              value={fontSize}
            />
          </div>
          <div className={`render-group__checkbox`}>
            <input
              type="checkbox"
              id={TEXT_CAPS}
              checked={textCaps}
              onChange={() => {
                setTextCaps(!textCaps);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor={TEXT_CAPS}>
              <span className="custom-checkbox"></span>
              <span className="label-text">Text Caps</span>
            </label>
          </div>
          <div
            style={{
              backgroundColor: color,
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleColorPicker()}
          ></div>
        </div>

        <div className="rename__input-group auto__beat">
          <textarea
            className="rename__input"
            value={text}
            onChange={handleChange}
            placeholder="Type your text ..."
            ref={textareaRef}
            style={{ overflow: "hidden", resize: "none" }}
          />
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("insertText", {
                textString: text,
                textCaps: textCaps,
                fontSize: fontSize,
                splitType: splitType,
                alignment: alignment,
                color: hexToRgbArray(color),
                stroke: false,
              })
            }
            className={`render-group__button ${
              text && fontSize && splitType && alignment ? "" : "disabled"
            }`}
          >
            <i className="fa-regular fa-play"></i>
            <span className="render-group__button-text">Create</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default InsertText;
