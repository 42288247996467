import useVisible from "../../hooks/useVisible.jsx";

function MotionAnimator({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("MotionAnimatorGroupVisibility");
  const [animatorData, setMotionAnimatorData] = useVisible(
    "setMotionAnimatorData"
  );

  const handleClick = (script, argument) => {
    if (script !== "pasteMotionAnimator") {
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    }
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (
        result &&
        result !== "undefined" &&
        result !== "false" &&
        script === "copyMotionAnimator"
      ) {
        setMotionAnimatorData(result);
      }
    });
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-light fa-arrows-up-down-left-right"></i>
          <div />
        </div>
        <div className="button-card__title">Motion Animator</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "all" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>All Transform</span>
        </div>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "position" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-arrows-up-down-left-right"></i>
          <span>Position</span>
        </div>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "scale" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-light fa-arrows-maximize"></i>
          <span>Scale</span>
        </div>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "rotation" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-rotate-right"></i>
          <span>Rotation</span>
        </div>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "opacity" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-circle-half-stroke"></i>
          <span>Opacity</span>
        </div>
        <div
          onClick={() =>
            handleClick("motionAnimator", JSON.stringify({ type: "shift" }))
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-swap-arrows"></i>
          <span>Position Shift</span>
        </div>
        <div
          onClick={() => handleClick("oldMotionAnimator", false)}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-arrows-up-down-left-right"></i>
          <span>Old Animator</span>
        </div>
        <div
          onClick={() => handleClick("copyMotionAnimator", false)}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-copy"></i>
          <span>Copy</span>
        </div>
        <div
          onClick={() => handleClick("pasteMotionAnimator", animatorData)}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-paste"></i>
          <span>Paste</span>
        </div>
        <div
          onClick={() => handleClick("deleteMotionAnimator", false)}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-trash"></i>
          <span>Delete</span>
        </div>
      </div>
    </>
  );
}

export default MotionAnimator;
