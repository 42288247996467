// useCepStorage.js
import { useState, useEffect, useRef } from "react";
import { saveCepStorage, getCepStorage } from "../utils/cepStorage.js";

export function useCepStorage(key, defaultValue = null) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = getCepStorage(key);
    return item !== null ? item : defaultValue;
  });

  const prevValueRef = useRef(storedValue);

  useEffect(() => {
    // Only save if the value has actually changed
    if (JSON.stringify(prevValueRef.current) !== JSON.stringify(storedValue)) {
      saveCepStorage(key, storedValue);
      prevValueRef.current = storedValue;
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}
