import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function Markers({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("MarkerGroupVisibility");

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-location-pin"></i>
          <div />
        </div>
        <div className="button-card__title">Markers</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("reverseMarker", { type: "comp" })}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-light fa-location-check"></i>
          <span>Comp Marker</span>
        </div>
        <div
          onClick={() => handleClick("reverseMarker", { type: "layer" })}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-light fa-location-plus"></i>
          <span>Layer Marker</span>
        </div>
        <div
          onClick={() => handleClick("addProtected", false)}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-shield-plus"></i>
          <span>Protected Marker</span>
        </div>
      </div>
    </>
  );
}

export default Markers;
