import React, { useContext, useState, useRef, useEffect } from "react";
import UserProfile from "../UserProfile/UserProfile";
import headerLogo from "../../images/header-logo.png";
import handleClick from "../../utils/handleClick.js";
import "./Header.css";

import { capitalizeFirstLetter } from "../../utils/utils.js";

const Header = ({
  handleUpdateUser,
  handleSignOut,
  lastHash,
  findButton,
  setFindButton,
}) => {
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showHash, setShowHash] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef(null);

  const toggleUserProfile = () => {
    setShowUserProfile(!showUserProfile);
  };

  const toggleHashDisplay = () => {
    setShowHash(!showHash);
  };

  const toggleSearchInput = () => {
    setShowSearchInput((prev) => {
      if (prev) {
        setFindButton("");
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (showSearchInput) {
      searchInputRef.current.focus();
    }
  }, [showSearchInput]);

  return (
    <>
      <UserProfile
        isShown={showUserProfile}
        onClose={toggleUserProfile}
        handleUpdateUser={handleUpdateUser}
        handleSignOut={handleSignOut}
        lastHash={lastHash}
      />
      <div className="header">
        <div>
          <img
            src={headerLogo}
            className="header__logo"
            alt="logo"
            onClick={toggleHashDisplay}
          />
        </div>
        {showHash && <div className="header__hash">{lastHash}</div>}
        <div className="header__controls">
          <i
            className={`fa ${
              showSearchInput ? "fa-times" : "fa-magnifying-glass"
            } header__profile`}
            onClick={toggleSearchInput}
          ></i>
          {showSearchInput && (
            <input
              type="text"
              className="rename__input"
              ref={searchInputRef}
              value={findButton}
              onChange={(e) => setFindButton(e.target.value)}
            />
          )}
          <div className="header__user_group">
            {!showSearchInput && (
              <div
                className="header__profile"
                onClick={() => handleClick("$.ae.openTemplateFolder")}
              >
                <i class="fa-regular fa-folder-open"></i>
              </div>
            )}
            <div className="header__profile" onClick={toggleUserProfile}>
              <i className="fa-sharp fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
