import React from "react";
import { useData } from "../../contexts/DataContext";

const TextValidation = ({ param, validate }) => {
  const { data, setData } = useData();

  const addFixAndAlign = (param) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      `app.beginUndoGroup('Add Fix and Align')
      ${param.validate.vbFit.script}
      app.endUndoGroup();
  `,
      function (result) {}
    );

    const paramPath = Object.keys(data.variants.default.params).find(
      (key) => data.variants.default.params[key] === param
    );

    if (!paramPath) {
      console.error("Параметр не найден!");
      return;
    }

    // Обновляем данные
    const updatedData = { ...data };
    updatedData.variants.default.params[paramPath].validate.vbFit.valid = true;
    updatedData.variants.default.params[
      paramPath
    ].validate.leading.valid = true;
    setData(updatedData);
  };

  const pointToText = (param) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      `app.beginUndoGroup('Point Text')
      ${param.validate.paragraphText.script}
      app.endUndoGroup();
  `,
      function (result) {}
    );

    const paramPath = Object.keys(data.variants.default.params).find(
      (key) => data.variants.default.params[key] === param
    );

    if (!paramPath) {
      console.error("Параметр не найден!");
      return;
    }

    window.jsx.evalScript(
      `run(vbValidator({selection:[${data.selection}]}));`,
      function (result) {
        if (result && result !== "undefined") {
          result = JSON.parse(result);
          setData(result);
        }
      }
    );

    // Обновляем данные
    // const updatedData = { ...data };
    // updatedData.variants.default.params[
    //   paramPath
    // ].validate.paragraphText.valid = true;
    // setData(updatedData);
  };

  return (
    <div className="param-info">
      <div>
        {/* <p>Comp: {containingComp}</p> */}
        {Object.entries(validate)
          .filter(([_, value]) => value !== undefined && !value.valid) // Отображаем только невалидные свойства
          .map(([key, value]) => (
            <p
              key={key}
              className={`param-description ${
                value.name === "Fit and Align" || "Paragraph Text"
                  ? "param-script"
                  : ""
              }`}
              onClick={() => {
                if (value.name === "Fit and Align") addFixAndAlign(param);
                if (value.name === "Paragraph Text") pointToText(param);
              }}
            >
              <strong>{value.name}</strong>: {value.description}
            </p>
          ))}
      </div>
      {/* {imagePath && (
        <img
          src={`./images/${imagePath}`}
          alt="Text"
          className="param-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/api/placeholder/320/180";
          }}
        />
      )} */}
    </div>
  );
};

export default TextValidation;
